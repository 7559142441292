import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {GetHierarchyDefinitionModel} from "./models/get-hierarchy-definition.model";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HierarchyDefinitionService {

  constructor(private readonly http: HttpClient) {
  }

  getHierarchyDefinitions(): Observable<GetHierarchyDefinitionModel[]> {
    return this.http.get<GetHierarchyDefinitionModel[]>(`${environment.canaryMilestoneApiBaseUrl}/hierarchyDefinition`);
  }

}
