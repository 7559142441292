import {Action, Selector, State, StateContext} from "@ngxs/store";
import {LoadingState} from "../loading-state.enum";
import {HierarchyDefinitionStateModel} from "./models/hierarchy-definition-state.model";
import {Injectable} from "@angular/core";
import {
  GetHierarchyDefinitionModel
} from "../../api-services/milestone-api/hierarchy-definition/models/get-hierarchy-definition.model";
import {
  HierarchyDefinitionService
} from "src/app/api-services/milestone-api/hierarchy-definition/hierarchy-definition.service";
import {catchError, tap, throwError} from "rxjs";

export class GetHierarchyDefinitions {
  static readonly type = '[HierarchyDefinition] Get Hierarchy Definitions';
}

@State<HierarchyDefinitionStateModel>({
  name: 'hierarchyDefinitions',
  defaults: {
    state: LoadingState.loading,
    hierarchyDefinitions: null
  }
})
@Injectable()
export class HierarchyDefinitionState {

  private static readonly cacheExpiration = 1000 * 60 * 60 * 24;

  constructor(private readonly hierarchyDefinitionService: HierarchyDefinitionService) {
  }

  @Selector()
  static get(state: HierarchyDefinitionStateModel) {
    return state;
  };
  
  @Action(GetHierarchyDefinitions)
  getHierarchyDefinitions(ctx: StateContext<HierarchyDefinitionStateModel>) {
    const currentState = ctx.getState();

    if (currentState.state === LoadingState.loaded) {
      return;
    }

    const cachedHierarchyDefinitions = localStorage.getItem('hierarchyDefinitions');
    if (cachedHierarchyDefinitions) {
      try {
        const cachedHierarchyDefinition: CachedHierarchyDefinition = JSON.parse(cachedHierarchyDefinitions);

        if (cachedHierarchyDefinition.cachedDate && new Date().getTime() - new Date(cachedHierarchyDefinition.cachedDate).getTime() < HierarchyDefinitionState.cacheExpiration) {
          ctx.patchState({
            state: LoadingState.loaded,
            hierarchyDefinitions: cachedHierarchyDefinition.hierarchyDefinitions
          });

          return;
        } else {
          localStorage.removeItem('hierarchyDefinitions');
        }
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.error('Error parsing cached hierarchy definitions', e);

          localStorage.removeItem('hierarchyDefinitions');
        } else {
          ctx.patchState({
            state: LoadingState.error
          });
        }
      }
    }

    return this.hierarchyDefinitionService.getHierarchyDefinitions().pipe(
      tap(hierarchyDefinitions => {
        ctx.patchState({
          state: LoadingState.loaded,
          hierarchyDefinitions
        });

        localStorage.setItem('hierarchyDefinitions', JSON.stringify({
          cachedDate: new Date(),
          hierarchyDefinitions
        }));
      }),
      catchError((err) => {
        ctx.patchState({
          state: LoadingState.error
        });

        return throwError(() => err);
      })
    );
  }
}

interface CachedHierarchyDefinition {
  cachedDate: Date;
  hierarchyDefinitions: GetHierarchyDefinitionModel[];
}
